
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { PollListModel } from "@/domain/pollster/poll-list/model/PollListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { DeletePollModel } from "@/domain/pollster/delete-poll/model/DeletePollModel";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { UpdatePollModel } from "@/domain/pollster/update-poll/model/UpdatePollModel";
import { Modal } from "bootstrap";
import { hideModal } from "@/presentation/helper/Dom";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const url = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const pollList = ref<PollListModel[]>([]);
    const originalPollList = ref<PollListModel[]>([]);
    const isChanged = ref<boolean>(false);
    const newEndDate = ref('');
    const selectedPollId = ref<number>();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const getPollList = async (page) => {
      isChanged.value = false;
      pollList.value = [];
      originalPollList.value = [];

      isLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const pollListModel: PollListModel = {
        poll: {},
        page: page,
        pageSize: pageSize.value,
      };

      isLoading.value = true;

      pollsterController
        .pollList(pollListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((poll) => {
              totalItems.value = poll.pagination.total;
              currentPage.value = poll.pagination.page;
              pageCount.value = poll.pagination.pageCount;
              pollList.value.push(poll);
              originalPollList.value.push(poll);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const deletePoll = (pollId) => {
      const pollDeleteModel: DeletePollModel = {
        id: pollId,
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_DELETE_POLL),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          pollsterController
            .deletePoll(pollDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                isChanged.value = true;
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_POLL),
                  icon: "success",
                  heightAuto: false,
                }).then(() => {});
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updatePollDate = () => {
      const pollUpdateModel: UpdatePollModel = {};
      pollUpdateModel.id = selectedPollId.value;
      pollUpdateModel.endDate = new Date(newEndDate.value);

      pollsterController
        .updatePoll(pollUpdateModel)
        .then((response) => {
          if (response.isSuccess) {
            isChanged.value = true;

            Swal.fire({
              title: t("swalMessages.SUCCESSFUL"),
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_POLL),
              icon: "success",
              heightAuto: false,
            }).finally(() => {
                hideModal(changeDateRef.value);
            })
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
        
    };
    
    const hideModall = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();

  modalEl.classList.remove('show');

  const modalBackdrop = document.querySelector('.modal-backdrop');
  if (modalBackdrop) {
    modalBackdrop.remove();
  }

  modalEl.style.display = 'none';
    };

    const updatePollToClosed = async (id?: number) => {
      const pollUpdateModel: UpdatePollModel = {};
      pollUpdateModel.id = id;
      pollUpdateModel.completed = true;

      Swal.fire({
        title: "Emin misiniz?",
        text: "Bu anketi sonlandırmak ister misiniz?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        cancelButtonText: "İptal",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          pollsterController
        .updatePoll(pollUpdateModel)
        .then((response) => {
          if (response.isSuccess) {
            isChanged.value = true;
            
            Swal.fire({
              title: t("swalMessages.SUCCESSFUL"),
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_POLL),
              icon: "success",
              heightAuto: false,
            })
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
        }
      });
    };

    const selectPoll = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate ? new Date(oldDate).toISOString().split('T')[0] : '';
      selectedPollId.value = id;
    };

    watch(isChanged, (newValue) => {
      if (newValue) {
        getPollList(1);
      }
    });

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPollList(1);
    });

    return {
      url,
      pollList,
      deletePoll,
      form,
      newEndDate,
      updatePollDate,
      updatePollToClosed,
      changeDateRef,
      selectPoll,
      isLoading,
      getPollList,
      currentPage,
      pageCount,
      pageSize,
      totalItems,
    };
  },
});
