<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-5 mb-xl-8">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">{{
              $t("modals.pollster.polls")
            }}</span>
            <span class="text-muted fw-bold fs-7">{{
              $t("modals.pollster.pollsDesc")
            }}</span>
          </h3>

          <!--begin::Actions-->
          <div class="justify-content-end">
            <router-link to="/pollster/create">
              <button
                type="submit"
                class="btn btn-sm btn-primary"
              >
                {{ $t("modals.pollster.createPollster") }}
              </button>
            </router-link>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-5">
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div
              v-if="isLoading"
              style="
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="text-center px-5 pb-7">
                <img
                  alt="Logo"
                  class="max-h-25px"
                  src="/media/loading_icon.gif"
                  style="width: 35px"
                />
              </div>
            </div>
            <div class="table-responsive" v-else-if="pollList.length > 0">
              <table
                class="table align-middle table-row-dashed fs-6 gy-5 no-footer"
                id="kt_customers_table"
                role="grid"
              >
                <!--begin::Table-->
                <thead>
                  <tr
                    class="
                      text-start text-gray-400
                      fw-bolder
                      fs-7
                      text-uppercase
                      gs-0
                      text-center
                    "
                    role="row"
                  >
                    <td width="15%">{{ $t("modals.pollster.pollTitle") }}</td>
                    <td width="13%">{{ $t("modals.pollster.desc") }}</td>
                    <td width="8%">{{ $t("modals.pollster.type") }}</td>
                    <td width="13%">{{ $t("modals.pollster.startDate") }}</td>
                    <td width="13%">{{ $t("modals.pollster.endDate") }}</td>
                    <td width="7%">
                      {{ $t("modals.pollster.questionCount") }}
                    </td>
                    <td width="7%">
                      {{ $t("modals.pollster.sessionCount") }}
                    </td>
                    <td width="12%">{{ $t("modals.pollster.status") }}</td>
                    <td width="12%">{{ $t("common.process") }}</td>
                  </tr>
                </thead>
                <tbody class="fw-bold text-gray-600 text-center">
                  <tr
                    class="odd"
                    v-for="(poll, index) in pollList"
                    :key="index"
                  >
                    <td class="text-wrap">{{ poll.poll.title }}</td>
                    <td class="text-wrap">{{ poll.poll.description }}</td>

                    <td>
                      <!-- Devam ediyor -->
                      <span
                        v-if="poll.poll.isPrivate !== true"
                        class="badge badge-light-success"
                        >{{ $t("common.general") }}</span
                      >
                      <!-- Sonlandırıldı -->
                      <span
                        v-else
                        class="badge badge-light-danger"
                        >{{ $t("common.special") }}</span
                      >
                    </td>
                    <td>
                      {{ new Date(poll.poll.startDate).toLocaleDateString() }}
                    </td>
                    <td>
                      {{ new Date(poll.poll.endDate).toLocaleDateString() }}
                      <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#change_date_modal"
                        @click="selectPoll(poll.poll.id, poll.poll.endDate)"
                        v-show="poll.poll.status == 'ACTIVE'"
                      >
                        <span class="svg-icon svg-icon-3"
                          ><svg
                            fill="none"
                            viewBox="0 0 24 24"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              opacity="0.3"
                              d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                              fill="black"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                              fill="black"
                            ></path></svg></span
                      ></div>
                    </td>
                    <td>{{ poll.poll.surveyQuestionsCount }}</td>
                    <td>{{ poll.poll.sessionCount }}</td>
                    <td>
                      <!-- Devam ediyor -->
                      <span
                        v-if="poll.poll.status === 'ACTIVE'"
                        class="badge badge-light-success"
                        >{{ $t("common.continues") }}</span
                      >
                      <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                      >
                        <span
                          class="svg-icon svg-icon-1"
                          v-if="poll.poll.status === 'ACTIVE'"
                          @click="updatePollToClosed(poll.poll.id)"
                        >
                          <inline-svg
                            src="/media/icons/duotune/arrows/arr015.svg"
                          />
                        </span>
                      </div>

                      <!-- Sonlandırıldı -->
                      <span
                        v-if="poll.poll.status === 'COMPLETED'"
                        class="badge badge-light-danger"
                        >{{ $t("common.ended") }}</span
                      >
                    </td>
                    <td> 
        <a
                        class="
                          btn
                          btn-icon
                          btn-bg-light
                          btn-active-color-primary
                          btn-sm
                        "
                        >        
                        <router-link
          class="btn btn-sm btn-primary"
          style="color: white"
           :to="'/pollster/detail/' + poll.poll.id"
        ><span class="svg-icon svg-icon-3"
                          ><svg
                            fill="none"
                            viewBox="0 0 24 24"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              opacity="0.3"
                              d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                              fill="black"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                              fill="black"
                            ></path></svg></span
                      >
                    </router-link></a>

                      <a
                        href="#"
                        class="
                          btn
                          btn-icon
                          btn-bg-light
                          btn-active-color-primary
                          btn-sm
                        "
                        @click="deletePoll(poll.poll.id)"
                        v-show="poll.poll.sessionCount == 0"
                        ><span class="svg-icon svg-icon-3"
                          ><svg
                            fill="none"
                            viewBox="0 0 24 24"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                              fill="black"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              opacity="0.5"
                              d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                              fill="black"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              opacity="0.5"
                              d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                              fill="black"
                            ></path></svg></span
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table-->
              </table>
            </div>
            <div v-else class="card-px text-center">
              <div class="text-center px-5 pb-7"></div>
              <!--begin::Description-->
              <p class="text-gray-700 fs-6 fw-bold">
                <span>{{ $t("modals.pollster.zeroCount") }}</span>
              </p>
              <!--end::Description-->
            </div>
          </div>
                              <!--begin::Pagination-->
                              <div
            class="d-flex flex-stack flex-wrap pt-10"
            v-if="pollList.length != 0"
          >
            <!--begin::Controls-->
            <div class="d-flex flex-wrap my-1">
              <!--begin::Select wrapper-->
              <div class="m-0">
                <!--begin::Select-->
                <select
                  name="status"
                  data-control="select2"
                  data-hide-search="true"
                  class="
                    form-select form-select-white form-select-sm
                    fw-bolder
                    w-125px
                  "
                  v-model="pageSize"
                  @change="getPollList(1)"
                >
                  <option value="5">5</option>
                  <option value="10" selected>10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span>{{ $t("common.table.pagination.desc2") }}</span>
                <!-- Sayfa başı öğe sayısı -->

                <!--end::Select-->
              </div>
              <!--end::Select wrapper-->
            </div>
            <!--end::Controls-->

            <div class="fs-6 fw-bold text-gray-700">
              {{ totalItems }} {{ $t("common.table.pagination.desc") }}
              <!-- sonuç arasından -->
              {{ (currentPage - 1) * pageSize + 1 }} -
              {{ Math.min(currentPage * pageSize, totalItems) }}
              {{ $t("common.table.pagination.desc1") }}
              <!-- sonuç gösteriliyor. -->
            </div>

            <!--begin::Pages-->
            <ul class="pagination">
              <li
                class="page-item previous"
                @click="getPollList(currentPage - 1)"
                :class="{ disabled: currentPage === 1 }"
              >
                <a href="#" class="page-link"><i class="previous"></i></a>
              </li>

              <li
                v-for="page in pageCount"
                :key="page"
                class="page-item"
                :class="{ active: currentPage === page }"
              >
                <a href="#" class="page-link" @click="getPollList(page)">{{
                  page
                }}</a>
              </li>

              <li
                class="page-item next"
                @click="getPollList(currentPage + 1)"
                :class="{ disabled: currentPage === pageCount }"
              >
                <a href="#" class="page-link"><i class="next"></i></a>
              </li>
            </ul>
            <!--end::Pages-->
          </div>
          <!--end::Pagination-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->

  <div
    class="modal fade"
    id="change_date_modal"
    ref="changeDateRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            id="vaccinate_modal_form"
            :validation-schema="form"
            class="form"
            @submit.prevent="updatePollDate"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">
                {{ $t("modals.pollster.updateEndDate") }}</h1>
              <!--end::Title-->

              <!--begin::Description-->
              <div class="text-gray-400 fs-7">
                {{ $t("modals.pollster.updateEndDateDesc") }}
              </div>
              <!--end::Description-->
            </div>
            <!--end::Heading-->

            <div class="row mb-2">
              <div class="col-md-12">
                <div class="fv-row mb-10">
                  <label class="fs-6 fw-bold mb-2">{{
                    $t("modals.pollster.endDate")
                  }}</label>

                  <!--begin::Input-->
                  <div class="position-relative align-items-center">
                    <!--begin::Datepicker-->
                    <Field
                      v-model="newEndDate"
                      type="date"
                      name="form"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('modals.pollster.enterEndDate')"
                      :min="new Date().toISOString().split('T')[0]"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="Date" />
                      </div>
                    </div>
                    <!--end::Datepicker-->
                  </div>
                  <!--end::Input-->
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <!--begin::Submit button-->
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                  @click="updatePollDate()"
                >
                  <span class="indicator-label">
                    {{ $t("common.button.save") }}
                  </span>
                  <span class="indicator-progress">
                    {{ $t("common.pleaseWait") }}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <!--end::Submit button-->
              </div>
            </div>
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
</template>

<style>
body.swal2-height-auto {
  height: 100% !important;
}
.text-wrap {
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.6em; /* 2 satır sınırı için */
  line-height: 1.8em; /* Satır yüksekliği */
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { PollListModel } from "@/domain/pollster/poll-list/model/PollListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { DeletePollModel } from "@/domain/pollster/delete-poll/model/DeletePollModel";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { UpdatePollModel } from "@/domain/pollster/update-poll/model/UpdatePollModel";
import { Modal } from "bootstrap";
import { hideModal } from "@/presentation/helper/Dom";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const url = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const pollList = ref<PollListModel[]>([]);
    const originalPollList = ref<PollListModel[]>([]);
    const isChanged = ref<boolean>(false);
    const newEndDate = ref('');
    const selectedPollId = ref<number>();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const getPollList = async (page) => {
      isChanged.value = false;
      pollList.value = [];
      originalPollList.value = [];

      isLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const pollListModel: PollListModel = {
        poll: {},
        page: page,
        pageSize: pageSize.value,
      };

      isLoading.value = true;

      pollsterController
        .pollList(pollListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((poll) => {
              totalItems.value = poll.pagination.total;
              currentPage.value = poll.pagination.page;
              pageCount.value = poll.pagination.pageCount;
              pollList.value.push(poll);
              originalPollList.value.push(poll);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const deletePoll = (pollId) => {
      const pollDeleteModel: DeletePollModel = {
        id: pollId,
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_DELETE_POLL),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          pollsterController
            .deletePoll(pollDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                isChanged.value = true;
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_POLL),
                  icon: "success",
                  heightAuto: false,
                }).then(() => {});
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updatePollDate = () => {
      const pollUpdateModel: UpdatePollModel = {};
      pollUpdateModel.id = selectedPollId.value;
      pollUpdateModel.endDate = new Date(newEndDate.value);

      pollsterController
        .updatePoll(pollUpdateModel)
        .then((response) => {
          if (response.isSuccess) {
            isChanged.value = true;

            Swal.fire({
              title: t("swalMessages.SUCCESSFUL"),
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_POLL),
              icon: "success",
              heightAuto: false,
            }).finally(() => {
                hideModal(changeDateRef.value);
            })
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
        
    };
    
    const hideModall = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();

  modalEl.classList.remove('show');

  const modalBackdrop = document.querySelector('.modal-backdrop');
  if (modalBackdrop) {
    modalBackdrop.remove();
  }

  modalEl.style.display = 'none';
    };

    const updatePollToClosed = async (id?: number) => {
      const pollUpdateModel: UpdatePollModel = {};
      pollUpdateModel.id = id;
      pollUpdateModel.completed = true;

      Swal.fire({
        title: "Emin misiniz?",
        text: "Bu anketi sonlandırmak ister misiniz?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        cancelButtonText: "İptal",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          pollsterController
        .updatePoll(pollUpdateModel)
        .then((response) => {
          if (response.isSuccess) {
            isChanged.value = true;
            
            Swal.fire({
              title: t("swalMessages.SUCCESSFUL"),
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_POLL),
              icon: "success",
              heightAuto: false,
            })
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
        }
      });
    };

    const selectPoll = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate ? new Date(oldDate).toISOString().split('T')[0] : '';
      selectedPollId.value = id;
    };

    watch(isChanged, (newValue) => {
      if (newValue) {
        getPollList(1);
      }
    });

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPollList(1);
    });

    return {
      url,
      pollList,
      deletePoll,
      form,
      newEndDate,
      updatePollDate,
      updatePollToClosed,
      changeDateRef,
      selectPoll,
      isLoading,
      getPollList,
      currentPage,
      pageCount,
      pageSize,
      totalItems,
    };
  },
});
</script>
